export enum Route {
  root = '/',

  // Nested
  docs = '/docs',

  // Simple / public
  public = '/public',
  link_problem = '/public/link_problem',
  data_workshop = '/data-workshop',

  // Auth
  runs = '/auth/runs',
  admin = '/auth/admin',
  login = '/login',
  logout = '/auth/logout',
  reset_password = '/auth/reset_password',

}

export const route_for = (route: Route, ...params: string[]): string => {
  if (params.length === 0) {
    return route;
  } else {
    return route + '/' + params.join('/');
  }
}